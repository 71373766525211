import logo from '@/images/logo/logo.svg'
import logoBlack from '@/images/logo/logo-black.svg'
import Image from 'next/image'
import Link from "next/link";
import {Container} from "@/components/Container";
import {BurgerIcon} from "@/images/BurgerIcon";
import {useContext, useEffect, useState} from "react";
import clsx from "clsx";
import {ContactModalContext} from "@/context/ContactModalContext";
import {XIcon} from "@/images/XIcon";
import {ChevronRight} from "@/images/ChevronRight";
import {WhiteContext} from "@/context/WhiteContext";
import {getLinks, links, socials} from "@/content/menu";
import {useRouter} from "next/router";
import {useTranslations} from "next-intl";

export function Menu() {
    const [open, setOpen] = useState(false);
    const t = useTranslations('Menu');
    const links = getLinks(t)
    const {setContactModalOpen} = useContext(ContactModalContext);
    const {white, setWhite, beforeModalOpen, setBeforeModalOpen} = useContext(WhiteContext);
    const {locale} = useRouter();

    useEffect(() => {
        const nav = document.querySelector("#fixed-nav-bar");
        const whiteSections = document.querySelectorAll('[data-white]');
        let observer;

        const resetObserver = () => {
            if (observer) observer.disconnect();

            const {top, height} = nav.getBoundingClientRect();

            if (top < 0) {
                return
            }

            observer = new IntersectionObserver(entries => observerCallback(entries), {
                root: document,
                rootMargin: `-${top}px 0px -${window.innerHeight - top - height}px 0px`,
            });

            whiteSections.forEach((el) => observer.observe(el));
        };

        const observerCallback = (entries) => {
            let dataWhite = false;
            entries.forEach((entry) => {
                if (entry.isIntersecting) dataWhite = true;
            });

            setWhite(dataWhite)
        };

        window.addEventListener('resize', () => resetObserver());

        resetObserver();
    }, [])

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', white ? '#FFFFFF' : '#0F0C15')
    }, [white])

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : "initial";

        if (open) {
            setBeforeModalOpen(white)
            setWhite(false)
        } else {
            setWhite(beforeModalOpen)
        }
    }, [open])

    return <nav>
        <div id="fixed-nav-bar"
             className={clsx("fixed top-0 w-full z-50 bg-opacity-50 backdrop-blur-xl transition-colors duration-300", white ? 'bg-white text-midnight' : 'bg-midnight text-white')}>
            <Container className="flex items-center justify-between py-2 border-b border-white border-opacity-10">
                <div>
                    <Link href="/">
                        <Image
                            src={white ? logoBlack : logo}
                            alt="D'Agostino Digital"
                            className="h-6 w-auto"
                            priority
                        />
                    </Link>
                </div>

                <button onClick={() => setOpen(!open)}
                        className={clsx("w-10 h-10 flex items-center justify-center md:hidden")}
                        aria-label="Toggle menu">
                    {open ? <XIcon className={white ? 'fill-midnight' : 'fill-white'}/> :
                        <BurgerIcon className={white ? 'fill-midnight' : 'fill-white'}/>}
                </button>

                {/* Desktop menu */}
                <div className="hidden md:flex gap-4">
                    <div className="flex gap-2">
                        {links.map((link, index) =>
                            <Link key={index} href={link.link} className={clsx(
                                "opacity-80 font-medium rounded-full py-1.5 px-4 border hover:transition-colors",
                                white ? "bg-black bg-opacity-5 border border-black border-opacity-10 hover:border-opacity-30" : 'bg-white bg-opacity-5 border-white border-opacity-10 hover:border-opacity-30'
                            )}>
                                {link.title}
                            </Link>
                        )}
                    </div>

                    <button
                        onClick={() => setContactModalOpen(true)}
                        className="font-medium rounded-full py-1.5 px-4 bg-primary bg-opacity-20 border border-primary border-opacity-10 hover:border-opacity-30 hover:transition-colors"
                    >
                        Contact
                    </button>

                    <div className="flex items-center">
                        <div className={clsx(
                            "flex items-center border rounded-full overflow-hidden hover:transition-colors",
                            white ? "bg-black bg-opacity-5 border border-black border-opacity-10 hover:border-opacity-30" : 'bg-white bg-opacity-5 border-white border-opacity-10 hover:border-opacity-30'
                        )}>
                            <Link href="/" locale="nl" scroll={false}
                                  className={clsx("px-2", locale === 'nl' && 'bg-white rounded-full text-midnight')}>NL</Link>
                            <Link href="/" locale="en" scroll={false}
                                  className={clsx("px-2", locale === 'en' && 'bg-white rounded-full text-midnight')}>EN</Link>
                        </div>
                    </div>
                </div>
            </Container>
        </div>

        {/* Mobile menu */}
        <div className={clsx(
            'md:hidden bg-midnight transition-opacity duration-500 fixed z-40 h-full w-full inset-0',
            open ? 'opacity-100' : 'opacity-0 pointer-events-none'
        )}>
            <div className={clsx(
                'flex flex-col justify-between text-left transition-all duration-500 h-full w-full pt-20 px-8 pb-4',
                open ? 'translate-y-0 scale-100 blur-0' : '-translate-y-1 scale-110 blur-md'
            )}>
                <div className="">
                    <ul className="space-y-6">
                        {links.map((link, index) =>
                            <li key={index}>
                                <Link
                                    href={link.link}
                                    className="font-semibold text-4xl"
                                    onClick={() => setOpen(false)}
                                >
                                    {link.title}
                                </Link>
                            </li>
                        )}
                    </ul>

                    <div className="border-[#423F48] border-b-2 border-dashed my-6"/>

                    <div className="flex gap-2">
                        {socials.map((social) => <a key={social.link} href={social.link} target="_blank"
                                                    aria-label="Social link">
                            <social.icon className="fill-white"/>
                        </a>)}
                    </div>

                    <div className="flex flex-col gap-2 mt-6">
                        <Link href="mailto:lorenso@dagostino.digital"
                              className="text-2xl md:text-4xl font-semibold">
                            lorenso@dagostino.digital
                        </Link>
                        <Link href="tel:+31 6 50 277 538" className="text-2xl md:text-4xl font-semibold">
                            +31 6 50 277 538
                        </Link>
                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-center mb-4 text-2xl">
                        <div className={clsx(
                            "flex items-center border rounded-full overflow-hidden hover:transition-colors",
                            'bg-white bg-opacity-5 border-white border-opacity-10 hover:border-opacity-30'
                        )}>
                            <Link href="/" locale="nl" onClick={() => setOpen(false)} scroll={false}
                                  className={clsx("px-3", locale === 'nl' && 'bg-white rounded-full text-midnight')}>NL</Link>
                            <Link href="/" locale="en" onClick={() => setOpen(false)} scroll={false}
                                  className={clsx("px-3", locale === 'en' && 'bg-white rounded-full text-midnight')}>EN</Link>
                        </div>
                    </div>

                    <button
                        onClick={() => {
                            setOpen(false)
                            setContactModalOpen(true)
                        }}
                        className="bg-primary w-full font-semibold flex justify-center text-xl items-center text-center gap-3 rounded-full px-6 py-3 border border-white border-opacity-10">
                        {t('contact_button')}
                        <ChevronRight/>
                    </button>
                </div>
            </div>
        </div>
    </nav>
}
