import React, {Component, createContext} from 'react'

const WhiteContext = React.createContext();

class WhiteProvider extends Component {

    state = {
        white: false,
        beforeModalOpen: false,
    };

    setWhite = (value) => {
        this.setState({white: value});
    };

    setBeforeModalOpen = (value) => {
        this.setState({beforeModalOpen: value});
    };

    render() {
        return (
            <WhiteContext.Provider value={{
                ...this.state,
                setWhite: this.setWhite,
                setBeforeModalOpen: this.setBeforeModalOpen,
            }}>
                {this.props.children}
            </WhiteContext.Provider>
        );
    }
}

const WhiteConsumer = WhiteContext.Consumer;
export {WhiteConsumer, WhiteContext};
export default WhiteProvider;
