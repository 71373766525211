import {LinkedInIcon} from '@/images/socials/LinkedInIcon';

export const getLinks = (t) => [
    {
        'link': '#diensten',
        'title': t('services')
    },
    {
        'link': '#werkwijze',
        'title': t('process')
    },
    {
        'link': '#projecten',
        'title': t('projects')
    },
    {
        'link': '#over',
        'title': t('about')
    },
    // {
    //     'link': '#faq',
    //     'title': 'FaQ'
    // },
];

export const socials = [
    {
        icon: LinkedInIcon,
        link: 'https://www.linkedin.com/company/dagostino-digital/',
    }
]
