import React, {Component, createContext} from 'react'

const ContactModalContext = React.createContext();

class ContactModalProvider extends Component {

    state = {
        contactModalOpen: false,
    };

    setContactModalOpen = (value) => {
        this.setState({contactModalOpen: value});
    };

    render() {
        return (
            <ContactModalContext.Provider value={{
                ...this.state,
                setContactModalOpen: this.setContactModalOpen,
            }}>
                {this.props.children}
            </ContactModalContext.Provider>
        );
    }
}

const ContactModalConsumer = ContactModalContext.Consumer;
export {ContactModalConsumer, ContactModalContext};
export default ContactModalProvider;
