import '@/styles/globals.css'
import 'keen-slider/keen-slider.min.css'
import 'react-medium-image-zoom/dist/styles.css'

import {Urbanist} from 'next/font/google'
import {Menu} from "@/components/Menu";
import ContactModalProvider, {ContactModalContext} from "@/context/ContactModalContext";
import WhiteProvider from "@/context/WhiteContext";
import {NextIntlClientProvider} from "next-intl";
import {useRouter} from "next/router";

const urbanist = Urbanist({
    subsets: ['latin'],
    variable: '--font-urbanist',
})

export default function App({Component, pageProps}) {
    const {locale} = useRouter();

    return <NextIntlClientProvider
        locale={locale}
        messages={pageProps.messages}
    >
        <ContactModalProvider>
            <WhiteProvider>
                <style jsx global>{`
                    html {
                        font-family: ${urbanist.style.fontFamily};
                    }
                `}</style>
                <Menu/>
                <Component {...pageProps} />
            </WhiteProvider>
        </ContactModalProvider>
    </NextIntlClientProvider>
}
